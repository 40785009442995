import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import { DonationFormFragmentFragmentDoc, DonationFormAmountFragmentFragmentDoc, QuestionFragmentFragmentDoc } from './fragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTeamsFromCampaignIdQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String'];
}>;


export type GetTeamsFromCampaignIdQuery = { __typename?: 'Query', getTeamsFromCampaignId: Array<{ __typename?: 'DonationFormObject', id: string, donationFormFields?: Array<{ __typename?: 'DonationFormFieldObject', locale: Types.Locales, title: string }> | null }> };

export type GetTeamMembersQueryVariables = Types.Exact<{
  teamId: Types.Scalars['String'];
}>;


export type GetTeamMembersQuery = { __typename?: 'Query', getTeamMembers: { __typename?: 'DonationFormObjecthZNF2DMT', items?: Array<{ __typename?: 'DonationFormObject', color: string, id: string, path: string, isPrimaryCampaign: boolean, isPrimaryTeam: boolean, campaignId?: string | null, teamId?: string | null, target?: number | null, logoUrl?: string | null, category: Types.DonationFormCategory, formVolume?: number | null, donationFormFields?: Array<{ __typename?: 'DonationFormFieldObject', locale: Types.Locales, title: string }> | null }> | null } };

export type GetTeamWithVolumeQueryVariables = Types.Exact<{
  teamId: Types.Scalars['String'];
}>;


export type GetTeamWithVolumeQuery = { __typename?: 'Query', getTeamWithVolume: { __typename?: 'DonationFormObjectFHgmQAef', object?: { __typename?: 'DonationFormObject', id: string, volume?: number | null, logoUrl?: string | null, target?: number | null, redirectUrl?: string | null, isPrimaryTeam: boolean, teamId?: string | null, campaignId?: string | null, allowCheque: boolean, allowInHonour: boolean, bannerUrl?: string | null, bannerVideoUrl?: string | null, category: Types.DonationFormCategory, color: string, encourageMonthlyDonations: boolean, hasReceipt: boolean, hideFundraiseButton: boolean, formVolume?: number | null, path: string, isArchived: boolean, isPrimaryCampaign: boolean, locale: Types.Locales, notificationEmails?: GraphQL_NotificationEmailsScalar | null, stripeProductId?: string | null, askForAddress: boolean, allowTeamCreation?: boolean | null, allowFundraiserCreation?: boolean | null, ticketingId?: string | null, organizationId?: string | null, user?: { __typename?: 'UserObject', email?: string | null } | null, organization?: { __typename?: 'OrganizationObject', name: string, region?: string | null, country: Types.OrganizationCountry, stripeCustomAccountId?: string | null, phoneNumber?: string | null, hasAdministrativeInformation: boolean, hasCharityNumber: boolean, canGenerateReceipt: boolean, deprecatedMongoId?: string | null, signatureUrl?: string | null } | null, donationFormFields?: Array<{ __typename?: 'DonationFormFieldObject', id: string, title: string, description: string, emailContent?: string | null, emailObject?: string | null, chequeDescription?: string | null, locale: Types.Locales, postDonationUrl?: string | null, donationFormAmounts?: Array<{ __typename?: 'DonationFormAmountObject', id: string, amount: number, isRecurrent: boolean, message?: string | null, sortIndex: number, recurrenceInterval: Types.ProductDonationRecurrenceInterval }> | null }> | null } | null } };


export const GetTeamsFromCampaignIdDocument = gql`
    query getTeamsFromCampaignId($campaignId: String!) {
  getTeamsFromCampaignId(campaignId: $campaignId) {
    id
    donationFormFields {
      locale
      title
    }
  }
}
    `;

/**
 * __useGetTeamsFromCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetTeamsFromCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsFromCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsFromCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetTeamsFromCampaignIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamsFromCampaignIdQuery, GetTeamsFromCampaignIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamsFromCampaignIdQuery, GetTeamsFromCampaignIdQueryVariables>(GetTeamsFromCampaignIdDocument, options);
      }
export function useGetTeamsFromCampaignIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsFromCampaignIdQuery, GetTeamsFromCampaignIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamsFromCampaignIdQuery, GetTeamsFromCampaignIdQueryVariables>(GetTeamsFromCampaignIdDocument, options);
        }
export type GetTeamsFromCampaignIdQueryHookResult = ReturnType<typeof useGetTeamsFromCampaignIdQuery>;
export type GetTeamsFromCampaignIdLazyQueryHookResult = ReturnType<typeof useGetTeamsFromCampaignIdLazyQuery>;
export type GetTeamsFromCampaignIdQueryResult = Apollo.QueryResult<GetTeamsFromCampaignIdQuery, GetTeamsFromCampaignIdQueryVariables>;
export const GetTeamMembersDocument = gql`
    query getTeamMembers($teamId: String!) {
  getTeamMembers(teamId: $teamId) {
    items {
      color
      id
      path
      isPrimaryCampaign
      isPrimaryTeam
      campaignId
      teamId
      target
      logoUrl
      id
      category
      formVolume
      donationFormFields {
        locale
        title
      }
    }
  }
}
    `;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, options);
      }
export function useGetTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, options);
        }
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<GetTeamMembersQuery, GetTeamMembersQueryVariables>;
export const GetTeamWithVolumeDocument = gql`
    query getTeamWithVolume($teamId: String!) {
  getTeamWithVolume(teamId: $teamId) {
    object {
      id
      volume
      ...DonationFormFragment
      user {
        email
      }
      organization {
        name
        region
        country
        stripeCustomAccountId
        phoneNumber
        hasAdministrativeInformation
        hasCharityNumber
        canGenerateReceipt
        deprecatedMongoId
        signatureUrl
      }
      logoUrl
      target
      redirectUrl
      donationFormFields {
        id
        title
        description
        emailContent
        emailObject
        chequeDescription
        locale
        postDonationUrl
        donationFormAmounts {
          ...DonationFormAmountFragment
        }
      }
      isPrimaryTeam
      isPrimaryTeam
      teamId
      campaignId
    }
  }
}
    ${DonationFormFragmentFragmentDoc}
${DonationFormAmountFragmentFragmentDoc}`;

/**
 * __useGetTeamWithVolumeQuery__
 *
 * To run a query within a React component, call `useGetTeamWithVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamWithVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamWithVolumeQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamWithVolumeQuery(baseOptions: Apollo.QueryHookOptions<GetTeamWithVolumeQuery, GetTeamWithVolumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamWithVolumeQuery, GetTeamWithVolumeQueryVariables>(GetTeamWithVolumeDocument, options);
      }
export function useGetTeamWithVolumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamWithVolumeQuery, GetTeamWithVolumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamWithVolumeQuery, GetTeamWithVolumeQueryVariables>(GetTeamWithVolumeDocument, options);
        }
export type GetTeamWithVolumeQueryHookResult = ReturnType<typeof useGetTeamWithVolumeQuery>;
export type GetTeamWithVolumeLazyQueryHookResult = ReturnType<typeof useGetTeamWithVolumeLazyQuery>;
export type GetTeamWithVolumeQueryResult = Apollo.QueryResult<GetTeamWithVolumeQuery, GetTeamWithVolumeQueryVariables>;